/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        $('.sticky').Stickyfill();

        var swiper = [];
        $('.swiper-container').each(function(index){

            var $el = $(this);

            swiper[index] = $el.swiper({
                //Your options here:
                spaceBetween: 30,
                slidesPerView: 'auto',
                pagination: $el.find('.swiper-pagination')[0],
                paginationClickable: true,
                centeredSlides: true,
                grabCursor: true,
                slideToClickedSlide: true
            });
        });

        var archiveSwiper = [];
        $('.archive-swiper-container').each(function(index){

            var $el = $(this);

            archiveSwiper[index] = $el.swiper({
                //Your options here:
                spaceBetween: 30,
                slidesPerView: 4,
                pagination: $el.siblings('.row').find('.archive-swiper-pagination')[0],
                paginationClickable: true,
                grabCursor: true,
                  breakpoints: {
                      1200: {
                          slidesPerView: 3,
                          spaceBetween: 15
                      },
                      992: {
                          slidesPerView: 2,
                          spaceBetween: 15
                      },
                      768: {
                          slidesPerView: 1,
                          spaceBetween: 15
                      },
                      576: {
                          slidesPerView: 1,
                          spaceBetween: 15
                      }
                  }
            });
        });

        function initSideMenuSwipers() {
          var menuSwiper = [];
          $('.sidemenu-swiper-container').each(function(index){

              var $el = $(this);

              menuSwiper[index] = $el.swiper({
                  //Your options here:
                  pagination: $el.find('.swiper-pagination')[0],
                  spaceBetween: 15,
                  paginationClickable: true,
                  slidesPerView: 3,
                  grabCursor: true,
                  breakpoints: {
                      1200: {
                          slidesPerView: 3,
                          spaceBetween: 15
                      },
                      992: {
                          slidesPerView: 2,
                          spaceBetween: 15
                      },
                      768: {
                          slidesPerView: 1,
                          spaceBetween: 15
                      },
                      576: {
                          slidesPerView: 1,
                          spaceBetween: 15
                      }
                  }
              });
          });
        }

        function getSideMenuContent(e) {

          var url = $(e).attr('href');

          $.get( url, function( data ) {
            result = $(data).find('#ajax-content');

            $( "#ajax-content" ).replaceWith( result );
            initSideMenuSwipers();
            $('.cSideMenu').removeClass('cSideMenu--hidden');
          });
        }

        $( "body" ).on( "click",'a[data-sidemenu-tag]', function(event) {
          getSideMenuContent(this);
          $('a[data-sidemenu-tag]').removeClass('active');
          $(this).addClass('active');
          event.preventDefault();
        });


        function getLoadMore(e) {
          var url = $(e).attr('href');
          
          $.get( url, function( data ) {
            $('[data-loadmore]').append( data );
            $('[data-loaded]').last().hide().fadeIn();
            $('[data-loadmore-temp]').first().fadeOut().remove();
          });
        }

        $( "body" ).on( "click",'[data-loadmore-button]', function(event) {
          const button = this;
          $('[data-loadmore-button]').parent().fadeOut('fast', function() {
            $('[data-loadmore-loading]').fadeIn('slow',function() {
              getLoadMore(button);
            });
          });
          event.preventDefault();
        });


        // Hide Header on on scroll down
        var didScroll;
        var lastScrollTop = 0;
        var delta = 5;
        var navbarHeight = $('header.banner').outerHeight();

        $(window).scroll(function(event){
            didScroll = true;
        });

        function hasScrolled() {
            var st = $(this).scrollTop();
            
            // Make sure they scroll more than delta
            if(Math.abs(lastScrollTop - st) <= delta) {
              return;
            }
            
            // If they scrolled down and are past the navbar, add class .nav-up.
            // This is necessary so you never see what is "behind" the navbar.
            if (st > lastScrollTop && st > navbarHeight){
                // Scroll Down
                $('header.banner').removeClass('nav-down').addClass('nav-up');
                $('body').removeClass('nav-down').addClass('nav-up');
                // $('.dossier-nav-sticky').removeClass('sticky-top');
            } else {
                // Scroll Up
                if(st + $(window).height() < $(document).height()) {
                    $('header.banner').removeClass('nav-up').addClass('nav-down');
                    $('body').removeClass('nav-up').addClass('nav-down');
                    // $('.dossier-nav-sticky').addClass('sticky-top');
                }
            }
            
            lastScrollTop = st;
        }

         setInterval(function() {
            if (didScroll) {
                hasScrolled();
                didScroll = false;
            }
        }, 100);

        function openSideMenu() {
          $('.cSideMenu').removeClass('cSideMenu--hidden');
          $('body').addClass('modal-open');
        }

        function closeSideMenu() {
          $('.cSideMenu').addClass('cSideMenu--hidden');
          $('body').removeClass('modal-open');
        }

        $('body').on('click', '.js-openSideMenu', function(e) {
          if ($('.cSideMenu').hasClass("cSideMenu--hidden")) {
            openSideMenu();
            $('.icon', this).toggleClass('icon--hidden');
          } else {
            closeSideMenu();
            $('.icon', this).toggleClass('icon--hidden');
          }
          e.preventDefault();
          return false;

        });

        initSideMenuSwipers();

        jQuery(function(){ window._resizer = new Resizer(); });

        // scrollreveal
        window.sr = ScrollReveal();
        sr.reveal('.cArticleCard', { scale: 1});
        sr.reveal('.article_post > .py-1', {scale: 1});

        var videoSrc = $(".modal iframe").attr("src");

        $('.modal').on('show.bs.modal', function () { // on opening the modal
          // set the video to autostart
          $(".modal iframe").attr("src", videoSrc+"&amp;autoplay=1");
        });

        $(".modal").on('hidden.bs.modal', function (e) { // on closing the modal
          // stop the video
          $(".modal iframe").attr("src", null);
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        if ($('.homehero').length ){

          var $tophero = $('.homehero').height();
          $('.fixed-links').css({'transform':'translate3d(0,' + $tophero + 'px, 0)'});
          $(window).scroll(function(){
            var $distance = $tophero - $('body').scrollTop();
            if ($('body').scrollTop() < $tophero) {
              $('.fixed-links').css({'transform':'translate3d(0,' + $distance + 'px, 0)'});
            } else {
              $('.fixed-links').css({'transform':'none'});

            }
          }); 
        }

        var swiper = [];
        $('.home-swiper-container').each(function(index){

            var $el = $(this);

            swiper[index] = $el.swiper({
                //Your options here:
                spaceBetween: 0,
                grabCursor: true,
                slidesPerView: 'auto',
                centeredSlides: true,
                slideToClickedSlide: true,
                preventClicks: true,
                preventClicksPropagation: true
            });
        });
        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'single_channel': {
      init: function() {
        // $(window).scroll(function() {
        //     if ($(window).scrollTop() > 500) {
        //         $('.dossier-nav-sticky').addClass('sticky-top');
        //     }
        //     else {
        //         $('.dossier-nav-sticky').removeClass('sticky-top');
        //     }
        // });
      }
    },
    'single': {
      init: function() {
        
          $(window).scroll(function() {
            var wintop = $(window).scrollTop(), docheight = $('body').height(), winheight = $(window).height();
            // console.log(wintop);
            var totalScroll = (wintop/(docheight-winheight))*100;
            // console.log("total scroll" + totalScroll);
            $("#progress").css("height",totalScroll+"%");

            checkOffset()
          });
          checkOffset()

          function checkOffset() {
            // console.log('wtf');
            if ($('#reading-progress').length) {
              if($('#reading-progress').offset().top + $('#reading-progress').height() >= $('footer.footer').offset().top - 10)
                $('#reading-progress').addClass('absolute');
              if($(document).scrollTop() + window.innerHeight < $('footer.footer').offset().top)
                $('#reading-progress').removeClass('absolute'); // restore when you scroll up
            }
          }

          // scroll to anchor
          $(document).on('click', 'a[href^="#"]', function (event) {
              event.preventDefault();
              // window.location.hash = $(this).attr('href');
              $('html, body').animate({
                  scrollTop: $($.attr(this, 'href')).offset().top
              }, 500);
          });

          var $nav = $('.dossier-nav .nav-link.active');
          if ($nav && $nav.length && $nav.length > 0) {
            $nav.trigger('click');
          }

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);


var bindFacebookEvents, initializeFacebookSDK, loadFacebookSDK, restoreFacebookRoot, saveFacebookRoot;

$(function() {
  loadFacebookSDK();
  if (!window.fbEventsBound) {
    return bindFacebookEvents();
  }
});

bindFacebookEvents = function() {
  $(document).on('page:fetch', saveFacebookRoot).on('page:change', restoreFacebookRoot).on('page:load', function() {
    return typeof FB !== "undefined" && FB !== null ? FB.XFBML.parse() : void 0;
  });
  return this.fbEventsBound = true;
};

saveFacebookRoot = function() {
  if ($('#fb-root').length) {
    return this.fbRoot = $('#fb-root').detach();
  }
};

restoreFacebookRoot = function() {
  if (this.fbRoot != null) {
    if ($('#fb-root').length) {
      return $('#fb-root').replaceWith(this.fbRoot);
    } else {
      return $('body').append(this.fbRoot);
    }
  }
};

loadFacebookSDK = function() {
  window.fbAsyncInit = initializeFacebookSDK;
  return $.getScript("//connect.facebook.net/en_US/sdk.js");
};

initializeFacebookSDK = function() {
  return FB.init({
    appId: '1434706216593328',
    status: true,
    cookie: true,
    xfbml: true,
    version: 'v2.9'
  });
};

})(jQuery); // Fully reference jQuery after this point.




var Resizer,
  bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

Resizer = (function() {
  Resizer.prototype.breakpoints = {
    xs: 0,
    sm: 768,
    md: 992,
    lg: 1200
  };

  Resizer.prototype.target = null;

  Resizer.prototype.timer = null;

  function Resizer(target) {
    if (target == null) {
      target = '.adaptive-bg';
    }
    this.onResize = bind(this.onResize, this);
    this.target = target;
    jQuery(window).on('resize', (function(_this) {
      return function(e) {
        e.preventDefault();
        clearTimeout(_this.timer);
        return _this.timer = setTimeout(_this.onResize, 100);
      };
    })(this));
    jQuery(window).on('scroll', (function(_this) {
      return function(e) {
        e.preventDefault();
        clearTimeout(_this.timer);
        return _this.timer = setTimeout(_this.onResize, 50);
      };
    })(this));
    this.onResize();
  }

  Resizer.prototype.onResize = function() {
    var $window, radius, self, size, viewport;
    $window = jQuery(window);
    size = $window.width();
    radius = 0.5;
    viewport = {
      width: $window.width(),
      height: $window.height(),
      top: $window.scrollTop(),
      bottom: $window.scrollTop() + $window.height(),
      left: $window.scrollLeft(),
      right: $window.scrollLeft() + $window.width()
    };
    viewport.top += radius * viewport.height / 2;
    viewport.bottom += radius * viewport.height / 2;
    viewport.left += radius * viewport.width / 2;
    viewport.right += radius * viewport.width / 2;
    self = this;
    return jQuery(this.target).each(function(i, e) {
      var $fakeImg, $this, bounds, isImg, key, src, visibility;
      $this = jQuery(this);
      bounds = $this.offset();
      bounds.right = bounds.left + $this.outerWidth();
      bounds.bottom = bounds.top + $this.outerHeight();
      visibility = {
        x: false,
        y: false
      };
      isImg = jQuery(this).is('img');
      if (isImg) {
        return;
      }
      visibility.y = (bounds.top >= viewport.top && bounds.top <= viewport.bottom) || (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) || (bounds.top <= viewport.top && bounds.bottom >= viewport.bottom);
      if (!visibility.y) {
        return;
      }
      src = null;
      for (key in self.breakpoints) {
        if (self.breakpoints[key] <= size && ($this.attr('data-src-' + key) != null)) {
          src = $this.attr('data-src-' + key);
        }
      }
      if (src != null) {
        return $fakeImg = jQuery('<img />').attr('src', src).load(function() {
          return $this.css('background-image', 'url(' + src + ')');
        });
      }
    });
  };

  return Resizer;

})();

window.Resizer = Resizer;
